import { __spreadArrays } from "tslib";
import { App, makeBooleanDataField, makeInt64DataField, makeStringDataField, Session, SuppressNexus, TelemetryLogger, User } from '@microsoft/oteljs';
import { OneDSEndpoint, OneDSSink } from '@microsoft/oteljs-1ds';
import { ACTION_DETAILS, ACTION_RESULT, ACTION_TARGET, ACTION_TYPE, APP_WORKLOAD, AREA, AUDIENCE_GROUP, BROWSER_SESSION_ID, DEPLOYMENT_ENV, DEVICE_TYPE, DIAGNOSTIC_DETAILS, DIAGNOSTIC_TABLE_NAME, DOMAIN_ORIGIN, ERROR_CATEGORY, ERROR_COUNT, ERROR_DETAILS, ERROR_TYPE, EVENT_CATEGORY, EVENT_NAME, EVENT_SCHEMA_VERSION, FIELD_NAME_SEPARATOR, FLIGHTS, ID, IS_INTENTIONAL, PAGE_NAME, PAGE_TYPE, PAGE_VIEW_DETAILS, RELEASE, REQUEST_ORIGIN, SCHEMA_VERSION, SERVER_LOCATION, SUB_AREA } from '@office-start/telemetry-types';
import { filterPii, getFlattenedFieldName } from '@office-start/telemetry-utility';
export function getDeviceType() {
    var platforms = [
        { keyword: 'Windows NT', deviceType: 'PC' },
        { keyword: 'Windows XP', deviceType: 'PC' },
        { keyword: 'Macintosh', deviceType: 'Mac' },
        { keyword: 'Windows Phone', deviceType: 'WindowsPhone' },
        { keyword: 'iPad', deviceType: 'iPad' },
        { keyword: 'iPhone', deviceType: 'iPhone' },
        { keyword: 'Android', deviceType: 'AndroidPhone' },
        { keyword: 'Linux', deviceType: 'Linux' },
        { keyword: 'CrOS', deviceType: 'Chromebook' }
    ];
    var deviceInfo = { keyword: 'Other', deviceType: 'Other' };
    var loweredUserAgentString = window.navigator.userAgent.toLowerCase();
    platforms.some(function (value) {
        if (loweredUserAgentString.indexOf(value.keyword.toLowerCase()) !== -1) {
            deviceInfo = value;
            return true;
        }
        return false;
    });
    return deviceInfo.deviceType;
}
var StandaloneOtelLogger = /** @class */ (function () {
    function StandaloneOtelLogger() {
    }
    StandaloneOtelLogger.prototype.initializeUnAuthOTel = function (context) {
        if (!(context.oTelEnabled && context.oTelAriaTenant) || this.unAuthOtelLogger) {
            return;
        }
        this.nameSpace = context.oTelAriaNameSpace;
        this.eventCategoryConfig = context.eventCategoryConfig;
        this.unAuthOtelLogger = new TelemetryLogger();
        this.unAuthOtelLogger.setTenantToken(context.oTelAriaNameSpace, context.oTelAriaTenant, SuppressNexus);
        var persistentFields = this.getPartAFields(context.appName, context.appPlatform, context.appWorkload, context.bundleBuildDate, context.correlationId, context.deploymentEnvironment, context.domainOrigin, context.flights, context.isCorpNet, context.isTestTraffic, context.sessionId, context.serverLocation, context.requestOrigin);
        var unAuthOneDsSink = this.getOneDsSink(persistentFields, context.deploymentEnvironment);
        this.unAuthOtelLogger.addSink(unAuthOneDsSink);
    };
    StandaloneOtelLogger.prototype.initializeAuthOTel = function (context) {
        if (!(context.oTelEnabled && context.oTelAriaTenant) || this.authOtelLogger) {
            return Promise.resolve();
        }
        this.nameSpace = context.oTelAriaNameSpace;
        this.eventCategoryConfig = context.eventCategoryConfig;
        this.authOtelLogger = new TelemetryLogger();
        this.authOtelLogger.setTenantToken(context.oTelAriaNameSpace, context.oTelAriaTenant, SuppressNexus);
        var persistentFields = this.getPartAFields(context.appName, context.appPlatform, context.appWorkload, context.bundleBuildDate, context.correlationId, context.deploymentEnvironment, context.domainOrigin, context.flights, context.isCorpNet, context.isTestTraffic, context.sessionId, context.serverLocation, context.requestOrigin, context.identity);
        var authOneDsSink = this.getOneDsSink(persistentFields, context.deploymentEnvironment);
        this.authOtelLogger.addSink(authOneDsSink);
        if ((context.oTelDiagnosticAriaTenant && context.oTelDiagnosticNameSpace)) {
            this.authDiagnosticOtelLogger = new TelemetryLogger();
            this.authDiagnosticOtelLogger.setTenantToken(context.oTelDiagnosticNameSpace, context.oTelDiagnosticAriaTenant, SuppressNexus);
            this.authDiagnosticOtelLogger.addSink(authOneDsSink);
            this.diagnosticNameSpace = context.oTelDiagnosticNameSpace;
        }
        return Promise.resolve();
    };
    StandaloneOtelLogger.prototype.logEvent = function (eventName, params, isAnonymous, securityThreshold) {
        var eventSecurityLevel = securityThreshold !== null && securityThreshold !== void 0 ? securityThreshold : 0 /* Security */;
        var appSecurityLevel = this.getAppSecurityLevel();
        if ((isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger) && this.isEligible(params)) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }
            var fullyQualifiedName = [this.nameSpace, eventName].join('.');
            var dataFields = this.convertToDataFields(params);
            this.sendTelemetryEvent(isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger, fullyQualifiedName, dataFields, 2 /* ProductServiceUsage */, 110 /* RequiredServiceData */);
        }
    };
    StandaloneOtelLogger.prototype.logPageView = function (properties, isAnonymous) {
        var _a, _b;
        var params = {};
        var eventSecurityLevel = (_a = properties.securityThreshold) !== null && _a !== void 0 ? _a : 0 /* Security */;
        var appSecurityLevel = this.getAppSecurityLevel();
        params[EVENT_CATEGORY] = 'PageView';
        var eventName = undefined;
        if (properties.pageName && (isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger) && this.eventCategoryConfig.pageViewEnabled) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }
            params[EVENT_NAME] = (_b = properties.eventName) !== null && _b !== void 0 ? _b : '';
            params[getFlattenedFieldName([PAGE_VIEW_DETAILS, PAGE_NAME])] = properties.pageName;
            params[getFlattenedFieldName([PAGE_VIEW_DETAILS, PAGE_TYPE])] = this.getPageType(properties.pageType);
            params[getFlattenedFieldName([PAGE_VIEW_DETAILS, IS_INTENTIONAL])] = properties.isIntentional;
            eventName = ['PageView', properties.pageName].join(FIELD_NAME_SEPARATOR);
            // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
            var fullyQualifiedName = [this.nameSpace, eventName].join('.');
            // Part C fields: Different for each event category
            //   - EventCategory
            //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
            var dataFields = this.convertToDataFields(params);
            this.sendTelemetryEvent(isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger, fullyQualifiedName, dataFields, 2 /* ProductServiceUsage */, 110 /* RequiredServiceData */);
        }
    };
    StandaloneOtelLogger.prototype.logUnAuthUserAction = function (properties) {
        var _a, _b, _c, _d;
        var appSecurityLevel = this.getAppSecurityLevel();
        var eventSecurityLevel = (_a = properties.securityThreshold) !== null && _a !== void 0 ? _a : 0 /* Security */;
        if (this.unAuthOtelLogger && this.eventCategoryConfig.actionEnabled) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }
            var params = {};
            params[EVENT_CATEGORY] = 'Action';
            params[getFlattenedFieldName([ACTION_DETAILS, ACTION_TYPE])] = (_b = properties.type) !== null && _b !== void 0 ? _b : 'Click';
            params[getFlattenedFieldName([ACTION_DETAILS, ID])] = properties.id;
            params[getFlattenedFieldName([ACTION_DETAILS, AREA])] = properties.area;
            params[getFlattenedFieldName([ACTION_DETAILS, SUB_AREA])] = (_c = properties.subArea) !== null && _c !== void 0 ? _c : 'Other';
            params[getFlattenedFieldName([ACTION_DETAILS, ACTION_RESULT])] =
                properties.result;
            params[getFlattenedFieldName([ACTION_DETAILS, ACTION_TARGET])] =
                properties.target;
            // TODO: import from office-start/telemetry-type after the package update
            params[getFlattenedFieldName([ACTION_DETAILS, 'TargetId'])] = (_d = properties.targetId) !== null && _d !== void 0 ? _d : '';
            params[getFlattenedFieldName([ACTION_DETAILS, PAGE_NAME])] = properties.pageName;
            params[getFlattenedFieldName([ACTION_DETAILS, PAGE_TYPE])] = this.getPageType(properties.pageType);
            var eventName = ['Action', properties.result, properties.target].join(FIELD_NAME_SEPARATOR);
            // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
            var fullyQualifiedName = [this.nameSpace, eventName].join('.');
            // Part C fields: Different for each event category
            //   - EventCategory
            //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
            var dataFields = this.convertToDataFields(params);
            this.sendTelemetryEvent(this.unAuthOtelLogger, fullyQualifiedName, dataFields, 2 /* ProductServiceUsage */, 110 /* RequiredServiceData */);
        }
    };
    StandaloneOtelLogger.prototype.logAuthDiagnostic = function (properties, securityThreshold) {
        var _a, _b;
        var params = {};
        var eventSecurityLevel = securityThreshold !== null && securityThreshold !== void 0 ? securityThreshold : 0 /* Security */;
        var appSecurityLevel = this.getAppSecurityLevel();
        var eventName = undefined;
        if (this.authDiagnosticOtelLogger && this.eventCategoryConfig.diagnosticEnabled) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }
            params[EVENT_NAME] = properties.eventName;
            params[EVENT_CATEGORY] = 'Diagnostic';
            for (var key in properties.params) {
                params[getFlattenedFieldName([DIAGNOSTIC_DETAILS, key])] =
                    typeof properties.params[key] === 'string'
                        ? filterPii(properties.params[key])
                        : properties.params[key];
            }
            params[getFlattenedFieldName([DIAGNOSTIC_DETAILS, AREA])] = (_a = properties.area) !== null && _a !== void 0 ? _a : '';
            params[getFlattenedFieldName([DIAGNOSTIC_DETAILS, SUB_AREA])] = (_b = properties.subArea) !== null && _b !== void 0 ? _b : '';
            eventName = DIAGNOSTIC_TABLE_NAME;
            // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
            var fullyQualifiedName = [this.diagnosticNameSpace, eventName].join('.');
            // Part C fields: Different for each event category
            //   - EventCategory
            //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
            var dataFields = this.convertToDataFields(params);
            this.sendTelemetryEvent(this.authDiagnosticOtelLogger, fullyQualifiedName, dataFields, 2 /* ProductServiceUsage */, 110 /* RequiredServiceData */);
        }
    };
    StandaloneOtelLogger.prototype.logError = function (properties, isAnonymous, securityThreshold) {
        var _a, _b, _c, _d;
        var params = {};
        var eventSecurityLevel = securityThreshold !== null && securityThreshold !== void 0 ? securityThreshold : 0 /* Security */;
        var appSecurityLevel = this.getAppSecurityLevel();
        var eventName = undefined;
        if ((isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger) && this.eventCategoryConfig.errorEnabled) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }
            params[EVENT_NAME] = properties.eventName;
            params[EVENT_CATEGORY] = 'Error';
            params[getFlattenedFieldName([ERROR_DETAILS, ERROR_TYPE])] = (_a = properties.errorType) !== null && _a !== void 0 ? _a : '';
            params[getFlattenedFieldName([ERROR_DETAILS, AREA])] = (_b = properties.area) !== null && _b !== void 0 ? _b : '';
            params[getFlattenedFieldName([ERROR_DETAILS, SUB_AREA])] = (_c = properties.subArea) !== null && _c !== void 0 ? _c : '';
            params[getFlattenedFieldName([ERROR_DETAILS, ERROR_CATEGORY])] = (_d = properties.errorCategory) !== null && _d !== void 0 ? _d : '';
            if (properties.occurrenceCount) {
                params[getFlattenedFieldName([ERROR_DETAILS, ERROR_COUNT])] =
                    properties.occurrenceCount;
            }
            eventName = 'Error';
            // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
            var fullyQualifiedName = [this.nameSpace, eventName].join('.');
            // Part C fields: Different for each event category
            //   - EventCategory
            //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
            var dataFields = this.convertToDataFields(params);
            this.sendTelemetryEvent(isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger, fullyQualifiedName, dataFields, 2 /* ProductServiceUsage */, 110 /* RequiredServiceData */);
        }
    };
    // Used to log errors seen during bootstrap of react shell or angular officehome app.
    StandaloneOtelLogger.prototype.logBootError = function (errorName, details, resourceUrl) {
        var props = {};
        if (resourceUrl) {
            props['ResourceUrl'] = resourceUrl;
        }
        if (details) {
            props['Details'] = filterPii(details);
        }
        this.logError({ eventName: errorName }, false);
        this.logAuthDiagnostic({ eventName: errorName, params: props });
    };
    StandaloneOtelLogger.prototype.logAppBundleError = function (cdn) {
        var resourceDetails = this.getCDNResourceDetails(cdn);
        this.logBootError('App_Bundle_Parsing_Error', JSON.stringify(resourceDetails));
    };
    StandaloneOtelLogger.prototype.getCDNResourceDetails = function (cdn) {
        var details = [];
        if (performance && performance.getEntriesByType) {
            var resources = performance.getEntriesByType('resource');
            if (resources) {
                for (var i = 0; i < resources.length; i++) {
                    var resource = resources[i];
                    // Currently this check only evaluates to true on Chrome and Firefox. Other browsers don't have
                    // transferSize or decodedBodySize.
                    if ((resource.initiatorType === 'link' || resource.initiatorType === 'script') &&
                        typeof resource.transferSize !== 'undefined' && typeof resource.decodedBodySize !== 'undefined' &&
                        resource.name && resource.name.indexOf(cdn) >= 0) {
                        var filenameParts = resource.name.split('/');
                        var filename = filenameParts.length > 0 ? filenameParts[filenameParts.length - 1] : '';
                        var transferSize = resource.transferSize; // This will be 0 if resource comes from browser cache.
                        var decodedBodySize = resource.decodedBodySize; // Un-gzipped size of resource. Can compare with resource on server/blob storage to see if it was truncated.
                        details.push({ name: filename, tSize: transferSize, dSize: decodedBodySize });
                    }
                }
            }
        }
        return details;
    };
    StandaloneOtelLogger.prototype.isEligible = function (params) {
        switch (params[EVENT_CATEGORY]) {
            case "Action" /* Action */:
                return this.eventCategoryConfig.actionEnabled;
            case "Diagnostic" /* Diagnostic */:
                return this.eventCategoryConfig.diagnosticEnabled;
            case "Error" /* Error */:
                return this.eventCategoryConfig.errorEnabled;
            case "Feature" /* Feature */:
                return this.eventCategoryConfig.featureEnabled;
            case "Impression" /* Impression */:
                return this.eventCategoryConfig.impressionEnabled;
            case "PageView" /* PageView */:
                return this.eventCategoryConfig.pageViewEnabled;
            case "Performance" /* Performance */:
                return this.eventCategoryConfig.perfEnabled;
            case "Request" /* Request */:
                return this.eventCategoryConfig.requestEnabled;
            default:
                return false;
        }
    };
    StandaloneOtelLogger.prototype.getAppSecurityLevel = function () {
        return typeof window.windowsDataSecuritySetting === 'number' ? window.windowsDataSecuritySetting : 3 /* Full */;
    };
    StandaloneOtelLogger.prototype.sendTelemetryEvent = function (logger, eventName, dataFields, dataCategories, diagnosticLevel) {
        logger.sendTelemetryEvent({
            eventName: eventName,
            dataFields: dataFields,
            eventFlags: {
                dataCategories: dataCategories,
                diagnosticLevel: diagnosticLevel
            }
        });
    };
    StandaloneOtelLogger.prototype.getPartAFields = function (appName, appPlatform, appWorkload, bundleBuildDate, correlationId, deploymentEnvironment, domainOrigin, flights, isCorpNet, isTestTraffic, sessionId, serverLocation, requestOrigin, identity) {
        // version : in format yyyymmd.x
        // TODO: Figure out a way to generate the version number
        var VERSION = '0';
        var buildDate = bundleBuildDate.split(' ')[0].split('-').join('');
        var appVersion = [buildDate, VERSION].join('.');
        var requiredPartADataFields = __spreadArrays(App.getFields({
            platform: appPlatform,
            name: appName,
            version: appVersion
        }), Session.getFields({
            id: correlationId
        }), [
            makeStringDataField(getFlattenedFieldName([RELEASE, AUDIENCE_GROUP]), this.getReleaseAudienceGroup(deploymentEnvironment, isCorpNet, isTestTraffic)),
            makeStringDataField(DEPLOYMENT_ENV, deploymentEnvironment)
        ]);
        if (identity) {
            var puid = identity === null || identity === void 0 ? void 0 : identity.puid;
            var idSpace = '';
            if (puid) {
                idSpace = (identity === null || identity === void 0 ? void 0 : identity.isMsa) ? 'MsaPuid' : 'OrgIdPuid';
            }
            requiredPartADataFields.push.apply(requiredPartADataFields, User.getFields({
                primaryIdentityHash: puid,
                primaryIdentitySpace: idSpace,
                tenantId: identity === null || identity === void 0 ? void 0 : identity.tenantId,
                tenantGroup: (identity === null || identity === void 0 ? void 0 : identity.isMsa) ? 'Consumer' : 'Commercial',
                isAnonymous: false
            }));
        }
        else {
            requiredPartADataFields.push.apply(requiredPartADataFields, User.getFields({
                isAnonymous: true
            }));
        }
        // TODO: Change the hard coded
        var customPartADataFields = [
            makeStringDataField(EVENT_SCHEMA_VERSION, SCHEMA_VERSION),
            makeStringDataField(REQUEST_ORIGIN, requestOrigin),
            makeStringDataField(BROWSER_SESSION_ID, sessionId),
            makeStringDataField(APP_WORKLOAD, appWorkload),
            makeStringDataField(SERVER_LOCATION, serverLocation),
            makeStringDataField(DEVICE_TYPE, getDeviceType()),
            makeStringDataField(FLIGHTS, flights),
            makeStringDataField(DOMAIN_ORIGIN, domainOrigin)
        ];
        var partADataFields = __spreadArrays(requiredPartADataFields, customPartADataFields);
        return partADataFields;
    };
    StandaloneOtelLogger.prototype.getReleaseAudienceGroup = function (deploymentEnvironment, isCorpNet, isTestTraffic) {
        if (isTestTraffic) {
            return 'Automation';
        }
        switch (deploymentEnvironment) {
            case "dev" /* Dev */:
            case "ppe" /* PPE */:
            case "prodprv" /* ProdPrv */:
                return 'Automation';
            case "prod" /* Prod */:
            case "bfprod" /* BFProd */:
            case "pfprod" /* PFProd */:
            case "tbprod" /* TBProd */:
            case "unprod" /* UNProd */:
            case "usprod" /* USProd */:
                if (isCorpNet) {
                    return 'Microsoft';
                }
                return 'Production';
            default:
                return 'Automation';
        }
    };
    StandaloneOtelLogger.prototype.getOneDsEndpoint = function (deploymentEnvironment) {
        switch (deploymentEnvironment) {
            case "pfprod" /* PFProd */:
                return OneDSEndpoint.USGOV_DOD;
            case "tbprod" /* TBProd */:
                return OneDSEndpoint.USGOV_DOJ;
            default:
                return OneDSEndpoint.PUBLIC;
        }
    };
    StandaloneOtelLogger.prototype.getOneDsSink = function (persistentDataFields, deploymentEnvironment) {
        return new OneDSSink(persistentDataFields, {
            endpointUrl: this.getOneDsEndpoint(deploymentEnvironment)
        });
    };
    StandaloneOtelLogger.prototype.convertToDataFields = function (data) {
        return Object.keys(data)
            .filter(function (key) {
            return typeof data[key] === 'number' ||
                typeof data[key] === 'boolean' ||
                typeof data[key] === 'string';
        })
            .map(function (key) {
            var value = data[key];
            switch (typeof value) {
                case 'number':
                    return makeInt64DataField(key, value);
                case 'boolean':
                    return makeBooleanDataField(key, value);
                case 'string':
                    return makeStringDataField(key, value);
            }
        });
    };
    StandaloneOtelLogger.prototype.getPageType = function (userType) {
        switch (userType) {
            case 'SignoutUserView':
                return 'SignOut';
            case 'ReturningUserView':
                return 'Returning';
            case 'NewUserView':
                return 'New';
            default:
                return 'Other';
        }
    };
    return StandaloneOtelLogger;
}());
(function () {
    window.standaloneOteLogger = new StandaloneOtelLogger();
})();
